"use client";
import { KeyboardShortcutContext } from "@contexts/KeyboardShortcutContext";
import { ReactNode, useEffect, useMemo, useState } from "react";

export const KeyboardShortcutProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [shortcuts, setShortcuts] = useState<Set<string>>(new Set());
  const handleKeyDown = (e: KeyboardEvent) => {
    const newShortcut = new Set([
      ...Array.from(shortcuts),
      e.key?.toLowerCase()
    ]);
    setShortcuts(newShortcut);
  };
  const handleKeyUp = (e: KeyboardEvent) => {
    const newShortcut = new Set([
      ...Array.from(shortcuts).filter((key) => key !== e.key?.toLowerCase())
    ]);
    setShortcuts(newShortcut);
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  const keyboardContextValue = useMemo(
    () => ({ keys: shortcuts }),
    [shortcuts]
  );
  return (
    <KeyboardShortcutContext.Provider value={keyboardContextValue}>
      {children}
    </KeyboardShortcutContext.Provider>
  );
};
